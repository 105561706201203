.store-edit-container {
  padding: 20px 0 0;
}

.store-edit-block {
  margin-bottom: 40px;
}

.store-edit-options {
  margin-bottom: 20px;
}

.store-edit__delivery-option {
  color: $black;
  font-size: 14px;
  font-weight: 500;

  &::after {
    display: table;
    clear: both;
    content: '';
  }

  .textfield-error {
    padding-top: 5px;
    clear: both;
  }
}

.store-edit__delivery-option-input {
  text-align: right;
}

.store-edit__delivery-option-label {
  display: inline;
  margin: 10px 0 0 10px;
  color: $grey-9;
}

.store-edit-link {
  color: $main-green;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.store-edit-hours {
  display: flex;
  margin-bottom: $space-small;
}

.store-edit-day {
  width: 100px;
}

.store-edit-buttons {
  margin-bottom: 30px;
  padding-top: 10px;
}

.store-edit-button {
  margin-right: 10px;
}

.store-edit__image-editor {
  display: flex;
}

.store-edit__image-dropdown-container {
  width: 100px;
}

@media (max-width: $max-width-screen-vert-tablet) {
  .store-edit-button {
    width: 100%;
    margin: 0 0 10px;
  }
}
