$item-border: #efefef;

.store-about-title {
  margin: 20px 0 10px;
  font-size: 14px;
}

.store-about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.store-about-column {
  width: 350px;
  margin-bottom: 30px;
}

.store-about-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid $item-border;
  font-weight: 500;
}

.store-about-label {
  color: $black;
  font-size: 16px;
}

.store-about-value {
  font-size: 16px;
}

@media (max-width: $max-width-screen-horiz-tablet) {
  .store-about-column--services {
    width: 230px;
  }
}

@media (max-width: $max-width-screen-vert-tablet) {
  .store-about-column {
    width: 100%;
  }

  .store-about-column--hours,
  .store-about-column--contact {
    order: 1;
  }

  .store-about-column--services {
    order: 2;
  }
}
