.members-modal-header {
  padding: 24px 20px 14px;
  background: $main-grey;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1.2;
  text-align: left;
  box-shadow: inset 0 -1px 0 0 $grey-c;
}

.members-modal-delete-container {
  display: flex;
}

.members-modal-delete {
  margin: 10px 0 25px;
  color: $main-red;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  margin-left: auto;
}
