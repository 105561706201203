.store-hours-container {
  padding: 20px 0;
  max-width: 380px;
}

.store-hours-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-small;
}

.store-hours-item-container {
  padding-left: 25px;
}

.store-hours-item--dayname {
  min-width: 110px;
}

.store-hours-time {
  display: flex;
  justify-content: space-between;
}

.store-hours-time--closed {
  color: $main-red;
  font-size: 14px;
  font-weight: 500;
}

.store-hours-select {
  width: 140px;
}

.store-hours-select + .store-hours-select {
  margin-left: $space-small;
}
