.profile-layout {
  max-width: 350px;
}

.profile-label {
  display: block;
  width: 100%;
  margin: 0 0 10px 0;
  padding-bottom: 4px;
  color: $grey-9;
  font-size: 14px;
  box-shadow: inset 0 -1px 0 0 $main-grey;
}
