.store-options {
  color: $black;
}

.store-options-description {
  font-size: 16px;
  font-weight: 500;
}

.store-options-input-container {
  width: 100%;
  max-width: 400px;
  margin-right: 20px;
}

.store-options-input {
  width: 100%;
  min-height: 90px;
}

@media (max-width: $max-width-screen-vert-tablet) {
  .store-options-input-container {
    max-width: none;
    margin-right: 0;
  }
}
