.stores-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 48px;
  box-shadow: 0 1px 0 0 $main-grey;

  .search-form {
    margin-bottom: 20px;
    width: 100%;

    @media (min-width: $min-width-screen-horiz-tablet) {
      margin-bottom: 0;
      min-width: 300px;
      width: auto;
    }
  }

  .toggle-buttons {
    margin-right: 40px;
  }
}

.stores-item-block {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.stores-item-name {
  max-width: 240px;
  margin-bottom: 6px;
  color: $main-green;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.stores-item-name--business {
  color: $black;
}

.stores-item-rating {
  display: flex;

  .text--footnote {
    line-height: 16px;
  }
}

@media only screen and (max-width: $max-width-screen-vert-tablet) {
  .stores {
    flex-direction: column;
  }

  .stores-item-name {
    max-width: none;
  }
}

@media only screen and (max-width: $max-width-screen-horiz-tablet) {
  .stores-header .toggle-buttons {
    display: none;
  }
}
