.store-members-container {
  padding: 20px 0;
}

.store-members-info {
  position: relative;
  margin-right: 20px;
  font-size: 13px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.3;

  &::before {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 12px;
    background: $main-grey;
    content: '';
    vertical-align: top;
  }
}

.store-members-info--active::before {
  background: $main-green;
}

.store-members-table {
  margin-bottom: 30px;
  color: $grey-9;
  font-size: 14px;
  font-weight: 500;
}

.store-members-type {
  margin-bottom: 10px;
  padding-bottom: 6px;
  border-bottom: 1px solid $main-grey;
}

.store-members-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 1px solid $main-grey;

  @media (min-width: $min-width-screen-desktop) {
    flex-wrap: nowrap;
  }
}

.store-members-status {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 12px;
  background: $main-grey;
}

.store-members-status--active {
  background: $main-green;
}

.store-members-name {
  display: flex;
  align-items: center;
  width: 310px;
  font-size: 14px;
  font-weight: 500;
}

.store-members-name--active {
  color: $black;
}

.store-members-email {
  color: $grey-6;
  font-size: 12px;
  font-weight: 500;
}

.store-members-phone {
  font-size: 12px;
  font-weight: 500;
}

.store-members-edit {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  color: $main-green;
  cursor: pointer;

  @media (min-width: $min-width-screen-horiz-tablet) {
    margin: 10px 0 0 10px;
  }

  @media (min-width: $min-width-screen-desktop) {
    margin: 0 0 0 auto;
  }
}

.store-members-empty {
  padding: 140px 0;
  text-align: center;
}

.store-members-description {
  line-height: 1.3;
}

.store-members-details {
  width: 300px;
}

@media (max-width: $max-width-screen-vert-tablet) {
  .store-members-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .store-members-info {
    margin-bottom: 5px;
  }

  .store-members-button,
  .store-members-staff {
    width: 100%;
  }

  .store-members-name {
    width: auto;
  }

  .store-members-details {
    width: 100%;
    padding: 5px 0;
  }

  .store-members-edit {
    margin-left: 0;
  }
}
