.profile-settings-title {
  margin: 20px 0;
}

.profile-settings-block {
  padding-bottom: 40px;
}

.profile-settings-block--last {
  padding-bottom: 0;
}

.profile-settings-item:not(:last-child) {
  margin-bottom: 14px;
}
