.store-history-container {
  padding: 20px 0;
}

.store-history-title {
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.store-history-title--active {
  color: $black;
}

.store-history-title--sortable {
  cursor: pointer;
}

.store-history-title--amount {
  text-align: right;
}

.store-history-item {
  color: $grey-6;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: $light-green--lightened;
  }
}

.store-history-amount {
  color: $black;
  text-align: right;
  font-size: 16px;
}

.store-history-info--small {
  color: $grey-9;
  font-size: $body-text-size-micro;
}

.store-history-info--danger {
  color: $main-red;
}

@media only screen and (max-width: $max-width-screen-vert-tablet) {
  .store-history-amount {
    text-align: left;
  }
}
